/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.loader-class {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.loader-class .ant-spin {
  margin: 50vh 50vw;
}
.descriptionContainer {
  overflow: hidden;
}
.descriptionContainer img {
  height: auto;
  max-width: 100%;
}
.descriptionContainer ul {
  list-style: circle inside;
}
.image-note {
  margin-top: 10px;
  display: inline-block;
  font-size: 12px;
  color: gray;
}
.front-doc {
  border: 1px solid;
  display: block;
  height: 200px;
  width: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 18px auto;
}
.front-doc img {
  width: 100%;
  height: 100%;
}
.back-doc {
  max-width: 150px;
  display: inline-block;
  max-height: 200px;
  border: 1px solid;
  margin-left: 30px;
}
.back-doc img {
  width: 100%;
  height: 100%;
}
.search-btn {
  border-radius: 5px !important;
  margin-left: 15px;
}
.radius-18 {
  border-radius: 18px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.field-error {
  color: red;
}

/* marker */

.mapMarkerStyle {
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -0.5rem;
  top: -0.5rem;
  position: relative;
  border-radius: 1.5rem 1.5rem 0;
  transform: rotate(45deg);
  border: 1px solid #ffffff;
}

.orderID {
  width: 240px !important;
  margin-left: -180px !important;
  margin-top: 20px !important;
  width: 50% !important;
}

.datesearch .orderID,
.datesearch .date,
.datesearch .searchButton {
  margin-left: 0 !important;
}
.datesearch .orderID {
  width: 100% !important;
}
.bordering {
  border-top: 1px solid #ccc !important;
  padding-top: 20px !important;
  margin-top: 5px !important;
}
.datesearch .searchButton {
  margin-top: 0 !important;
  top: 29px !important;
}
.datesearch .commonui {
  margin-left: 15px !important;
}

.date {
  margin-left: -318px !important;
  margin-top: 20px !important;
  margin-right: 30px !important;
  width: 80% !important;
}

.commonui {
  position: relative;
  top: 11px;
}

.managerui {
  position: relative;
  top: -12px;
}

.searchButton {
  margin-left: 315px !important;
  margin-top: -32px !important;
}

@media (max-width: 1224px) {
  .orderID {
    width: 200px !important;
    margin-left: -90px !important;
    margin-top: 20px !important;
    width: 50% !important;
  }

  .date {
    margin-left: -160px !important;
    margin-top: 20px !important;
    margin-right: 30px !important;
    width: 85% !important;
  }

  .searchButton {
    margin-left: 315px !important;
    margin-top: -32px !important;
  }
}

.mapMarkerStyle::after {
  position: "absolute";
  content: "";
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  background-color: red;
}
/* marker end */

.autocomplete-dropdown-container {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-top: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  width: 100%;
  z-index: 999;
}
.autocomplete-dropdown-container .suggestion-item {
  padding: 3px 10px;
}
.autocomplete-dropdown-container .suggestion-item--active {
  background-color: #4670a2 !important;
  color: white;
  padding: 3px 10px;
}

.orderItemTable {
  border: 1px solid gray;
  border-collapse: collapse !important;
}
.orderItemTable th,
.orderItemTable td {
  border: 1px solid gray;
}
.pl-12 {
  padding-left: 12px;
}
